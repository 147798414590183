@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Gordita Regular';
    src: url('../fonts/Gordita-Regular/Gordita-Regular.eot');
    src:
            url('../fonts/Gordita-Regular/Gordita-Regular.eot')
            format('embedded-opentype'),
            url('../fonts/Gordita-Regular/Gordita-Regular.woff2') format('woff2'),
            url('../fonts/Gordita-Regular/Gordita-Regular.woff') format('woff'),
            url('../fonts/Gordita-Regular/Gordita-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita Medium';
    src: url('../fonts/Gordita-Medium/Gordita-Medium.eot');
    src:
            url('../fonts/Gordita-Medium/Gordita-Medium.eot')
            format('embedded-opentype'),
            url('../fonts/Gordita-Medium/Gordita-Medium.woff2') format('woff2'),
            url('../fonts/Gordita-Medium/Gordita-Medium.woff') format('woff'),
            url('../fonts/Gordita-Medium/Gordita-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita Bold';
    src: url('../fonts/Gordita-Bold/Gordita-Bold.eot');
    src:
            url('../fonts/Gordita-Bold/Gordita-Bold.eot')
            format('embedded-opentype'),
            url('../fonts/Gordita-Bold/Gordita-Bold.woff2') format('woff2'),
            url('../fonts/Gordita-Bold/Gordita-Bold.woff') format('woff'),
            url('../fonts/Gordita-Bold/Gordita-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    @apply font-regular;
}

html,
body {
    @apply text-primary h-[100dvh] overflow-hidden;
}

#root {
    @apply min-h-[100dvh] flex;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply top-auto -bottom-[45px];
}

.swiper-pagination-bullet {
    @apply w-3 h-3 bg-transparent border-dark border border-solid opacity-100;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-dark;
}
